import React, { useEffect, useState } from "react";
import { BrowserRouter, Route } from "react-router-dom";
import * as EnvConstants from "../src/Constants/EnvConstants";
import { QueryStringProvider } from "./Contexts/QueryString";
import { VisitorParamsProvider } from "./Contexts/VisitorParams";
import { queryString } from "./Utility/QueryString";
import HomePage from "./Components/Pages/HomePage";
import FbPixel from "./Components/Pages/FbPixel";
import "./App.scss";
import { RedirectProvider } from "./Contexts/RedirectContext";
// import Questionnaire from "./Components/Pages/Questionnaire";
import TCG_L_B from "./Components/Pages/Splits/TCG_L_B";
import TCG_L_C from "./Components/Pages/Splits/TCG_L_C";
// import Signature from "./Components/Pages/Signature/Signature";
import CarRegistraionV2 from "./Components/Pages/CarRegistration/CarRegistraionV2";
import SignatureV1 from "./Components/Pages/Signature/SignatureV1";
// import CarRegistraion from "./Components/Pages/CarRegistration/CarRegistraion";
// import IdUpload from "./Components/Pages/IdUpload/IdUpload";
import Thankyou from "./Components/Pages/Thankyou/Thankyou";
import NotFound from "./Components/Pages/NotFound";
// import PdfView from"./Components/Pages/PdfView";
import AdtopiaLoadDynamic from "./Utility/AdtopiaLoadDynamic";
import CarRegistrationV3 from "./Components/Pages/CarRegistration/CarRegistrationV3";
import TCG_L_A from "./Components/Pages/Splits/TCG_L_A";
import SignatureV3 from "./Components/Pages/Signature/SignatureV3";
import IdUploadV1 from "./Components/Pages/IdUpload/IdUploadV1";
import HelmetBasedOnDomain from "./Components/Includes/Layouts/Common/HelmetBasedOnDomain";
import ThankyouV1 from "./Components/Pages/Thankyou/ThankyouV1";
import IdUploadV3 from "./Components/Pages/IdUpload/IdUploadV3";
import CarRegistraionV1 from "./Components/Pages/CarRegistration/CarRegistrationV1";
// import {getBrandName} from "./Components/Includes/Layouts/Common/BrandBasedOnDomain";
import SignatureV2 from "./Components/Pages/Signature/SignatureV2";
import TCG_L_C_2 from "./Components/Pages/Splits/TCG_L_C_2";
import CarRegistrationV4 from "./Components/Pages/CarRegistration/CarRegistrationV4";
import IdUploadV4 from "./Components/Pages/IdUpload/IdUploadV4";
import TCG_L_D from "./Components/Pages/Splits/TCG_L_D";
import ThankyouV4 from "./Components/Pages/Thankyou/ThankyouV4";
import Trigger_followup_strategy from "./Components/Followup/Trigger_followup_strategy";
import FollowUp from "./Components/Pages/FollowUp/FollowUp";
import FollowUpAuthorise from "./Components/Pages/FollowUp/FollowUpAuthorise";
import FollowUpVerification from "./Components/Pages/FollowUp/FollowUpVerification";
import FollowUpThankYou from "./Components/Pages/FollowUp/FollowUpThankYou";
import FollowUpCarRegistration from "./Components/Pages/FollowUp/FollowUpCarRegistration";
import FollowUpIdUpload from "./Components/Pages/FollowUp/FollowUpIdUpload";
import TCG_L_C_3 from "./Components/Pages/Splits/TCG_L_C_3";
import TCG_L_C_4 from "./Components/Pages/Splits/TCG_L_C_4";
import IdUploadV5 from "./Components/Pages/IdUpload/IdUploadV5";
import CarRegistrationV5 from "./Components/Pages/CarRegistration/CarRegistrationV5";
import SignatureV5 from "./Components/Pages/Signature/SignatureV5";
import AuthoriseC3 from "./Components/Pages/Authorise/AuthoriseC3";
import SignatureV3_C_4 from "./Components/Pages/Signature/SignatureV3_C_4";
import CarRegistrationV5_C_4 from "./Components/Pages/CarRegistration/CarRegistrationV5_C_4";
import IdUploadV5_C4 from "./Components/Pages/IdUpload/IdUploadV5_C4";
import TCGL_V_A1 from "./Components/Pages/Splits/TCGL_V_A1";
import SignatureV6 from "./Components/Pages/Signature/SignatureV6";
import TCGL_V_A1_CreditAnalysis from "./Components/Pages/TCGL_V_A1/TCGL_V_A1_CreditAnalysis";
import TCGL_V_A1_PersonalDetails from "./Components/Pages/TCGL_V_A1/TCGL_V_A1_PersonalDetails";
// import TCGL_V_A1_CreditcheckLenders from "./Components/Pages/TCGL_V_A1/TCGL_V_A1_CreditcheckLenders";
import TCGL_V_A1_Id_Upload from "./Components/Pages/TCGL_V_A1/TCGL_V_A1_Id_Upload";
import Loader from "./Components/Includes/Layouts/TCGL_V_A1/Loader";
import TCGL_V_A1_Lenders from "./Components/Pages/TCGL_V_A1/TCGL_V_A1_Lenders";
import TCGL_V_A1_Thankyou from "./Components/Pages/TCGL_V_A1/TCGL_V_A1_Thankyou";
import EditDetails from "./Components/Pages/EditDetails";
import CreditCheckEdit from "./Components/Pages/CreditCheckEdit";
import FollowUpV2 from "./Components/Pages/FollowUp/V2/FollowUpV2";
import FollowUpSignatureV2 from "./Components/Pages/FollowUp/V2/FollowUpSignatureV2";
import FollowUpCreditAnalysis from "./Components/Pages/FollowUp/V2/FollowUpCreditAnalysis";
import FollowUpId_Upload from "./Components/Pages/FollowUp/V2/FollowUpId_Upload";
import FollowUpLenders from "./Components/Pages/FollowUp/V2/FollowUpLenders";
import FollowUpEditDetails from "./Components/Pages/FollowUp/V2/FollowUpEditDetails";
import FollowUpCreditCheckEdit from "./Components/Pages/FollowUp/V2/FollowUpCreditCheckEdit";
import FollowUp_Thankyou from "./Components/Pages/FollowUp/V2/FollowUp_Thankyou";
import FollowupLoader from "./Components/Pages/FollowUp/V2/FollowupLoader";
import TryAgain from "./Components/Pages/TCGL_V_A1/TryAgain";
import Success from "./Components/Pages/TCGL_V_A1/Success";
import Duplicate from "./Components/Includes/Layouts/TCGL_V_A1/Duplicate";
import DuplicateFbPixel from "./Components/Pages/DuplicateFbPixel";
import TCGL_V_A2 from "./Components/Pages/Splits/TCGL_V_A2";
import TCGL_V_A2_PersonalDetails from "./Components/Pages/TCGL_V_A2/TCGL_V_A2_PersonalDetails";
import TCGL_V_A2_Lenders from "./Components/Pages/TCGL_V_A2/TCGL_V_A2_Lenders";
import SignatureV7 from "./Components/Pages/Signature/SignatureV7";
import TCGL_V_A2_CreditAnalysis from "./Components/Pages/TCGL_V_A2/TCGL_V_A2_CreditAnalysis";
import TCGL_V_A2_Id_Upload from "./Components/Pages/TCGL_V_A2/TCGL_V_A2_Id_Upload";
import EditDetails_A2 from "./Components/Pages/EditDetails_A2";
import CreditCheckEdit_A2 from "./Components/Pages/CreditCheckEdit_A2";
import TCGL_V_A3 from "./Components/Pages/Splits/TCGL_V_A3";
import TCGL_V_A3_PersonalDetails from "./Components/Pages/TCGL_V_A3/TCGL_V_A3_PersonalDetails";
import TCGL_V_A3_Lenders from "./Components/Pages/TCGL_V_A3/TCGL_V_A3_Lenders";
import SignatureV8 from "./Components/Pages/Signature/SignatureV8";
import TCGL_V_A3_CreditAnalysis from "./Components/Pages/TCGL_V_A3/TCGL_V_A3_CreditAnalysis";
import TCGL_V_A3_Id_Upload from "./Components/Pages/TCGL_V_A3/TCGL_V_A3_Id_Upload";
import EditDetails_A3 from "./Components/Pages/EditDetails_A3";
import CreditCheckEdit_A3 from "./Components/Pages/CreditCheckEdit_A3";

const App = () => {
  const [visitorParameters] = useState({ visitor_parameters: [], data: [] });
  const [redirectUrl] = useState({ redirectUrl: '' });
  // const brandName = getBrandName();
  // useEffect(() => {
  //   document.title = brandName;
  // }, []);
  return (
    <>
      <HelmetBasedOnDomain/>
      <VisitorParamsProvider value={{ visitorParameters }}>
        <QueryStringProvider value={{ queryString }}>
          <RedirectProvider value={{ redirectUrl }}>
            <BrowserRouter>
              <Route exact path="/" component={HomePage} />
              <Route exact path={["/pcp/TCG_L_B"]} component={TCG_L_B} />
              <Route exact path={["/pcp/TCG_L_C"]} component={TCG_L_C} />
              {/* <Route exact path={"/questionnaire"} component={Questionnaire} /> */}
              {/* <Route exact path={"/signature"} component={Signature} />*/}
              <Route exact path={"/v2/car-registration"} component={CarRegistraionV2} />
              <Route exact path={"/v1/signature"} component={SignatureV1} />
              {/* <Route exact path={"/car-registration"} component={CarRegistraion} /> */}
              <Route exact path={["/fbpixel"]} component={FbPixel} />
              <Route exact path={"/thankyou"} component={Thankyou} />
              {/* <Route exact path={["/id-upload"]} component={IdUpload} /> */}
              <Route exact path={["/404"]} component={NotFound} />
               {/*<Route exact path={["/:enviroment/:project_id/pdf/:doc_type/:filename"]} component={PdfView}/>*/}
              <Route exact path={["/google","/testing"]} component={AdtopiaLoadDynamic}/>
              <Route exact path={"/v3/car-registration"} component={CarRegistrationV3} />
              <Route exact path={["/pcp/TCG_L_A"]} component={TCG_L_A} />
              <Route exact path={"/v3/signature"} component={SignatureV3} />
              <Route exact path={"/v1/id-upload"} component={IdUploadV1} />
              <Route exact path={"/v1/thankyou"} component={ThankyouV1} />
              <Route exact path={"/v3/id-upload"} component={IdUploadV3} />
              <Route exact path={"/v2/signature"} component={SignatureV2} />
              <Route exact path={"/v1/car-registration"} component={CarRegistraionV1} />
              <Route exact path={"/pcp/TCG_L_C_2"} component={TCG_L_C_2}/>
              <Route exact path={"/v4/car-registration"} component={CarRegistrationV4} />
              <Route exact path={"/v4/id-upload"} component={IdUploadV4} />
              <Route exact path={"/pcp/TCG_L_D"} component={TCG_L_D}/>
              <Route exact path={"/v4/thankyou"} component={ThankyouV4} />
              <Route exact path={"/trigger-followup-strategy"} component={Trigger_followup_strategy}/>
              <Route exact path={"/followup"} component={FollowUp}/>
              <Route exact path={"/followup-authorise"} component={FollowUpAuthorise}/>
              <Route exact path={"/followup-verification"} component={FollowUpVerification}/>
              <Route exact path={"/followup-thank-you"} component={FollowUpThankYou}/>
              <Route exact path={"/followup-car-registration"} component={FollowUpCarRegistration}/>
              <Route exact path={"/followup-id-upload"} component={FollowUpIdUpload}/>
              <Route exact path={["/pcp/TCG_L_C_3"]} component={TCG_L_C_3} />
              <Route exact path={["/pcp/TCG_L_C_4"]} component={TCG_L_C_4} />
              <Route exact path={"/v5/id-upload"} component={IdUploadV5} />
              <Route exact path={"/v5/car-registration"} component={CarRegistrationV5} />
              <Route exact path={"/v5/signature"} component={SignatureV5} />
              <Route exact path={"/v5/authorise"} component={AuthoriseC3} />
              <Route exact path={"/v3_C4/signature"} component={SignatureV3_C_4} />
              <Route exact path={"/v5_C4/car-registration"} component={CarRegistrationV5_C_4} />
              <Route exact path={"/v5_C4/id-upload"} component={IdUploadV5_C4} />
              <Route exact path={"/TCGL_V_A1"} component={TCGL_V_A1} />
              <Route exact path={"/TCGL_V_A1/signature"} component={SignatureV6} />
              <Route exact path={"/TCGL_V_A1/credit-analysis"} component={TCGL_V_A1_CreditAnalysis} />
              <Route exact path={"/TCGL_V_A1/personal-details"} component={TCGL_V_A1_PersonalDetails} />
              {/* <Route exact path={"/TCGL_V_A1/creditcheck-lenders"} component={TCGL_V_A1_CreditcheckLenders} /> */}
              <Route exact path={"/TCGL_V_A1/id-upload"} component={TCGL_V_A1_Id_Upload} />
              <Route exact path={"/TCGL_V_A1/lenders"} component={TCGL_V_A1_Lenders} />
              <Route exact path={"/TCGL_V_A1/thankyou"} component={TCGL_V_A1_Thankyou} />
              <Route exact path={"/loader"} component={Loader} />
              <Route exact path={"/try_again"} component={TryAgain} />
              <Route exact path={"/success"} component={Success} />
              <Route exact path={"/edit-details"} component={EditDetails} />
              <Route exact path="/edit-credit-check" component={CreditCheckEdit} />
              <Route exact path={"/followupv2"} component={FollowUpV2}/>
              <Route exact path={"/followup-signature-v2"} component={FollowUpSignatureV2}/>
              <Route exact path={"/followup-credit-analysis-v2"} component={FollowUpCreditAnalysis}/>
              <Route exact path={"/followup-id-upload-v2"} component={FollowUpId_Upload}/>
              <Route exact path={"/followup-lenders-v2"} component={FollowUpLenders}/>
              <Route exact path={"/followup-edit-details"} component={FollowUpEditDetails} />
              <Route exact path="/followup-edit-credit-check" component={FollowUpCreditCheckEdit} />
              <Route exact path="/followup-thankyou" component={FollowUp_Thankyou} />
              <Route exact path="/followup-loader" component={FollowupLoader} />
              <Route exact path="/duplicate" component={Duplicate} />
              <Route exact path={["/duplicate-fbpixel"]} component={DuplicateFbPixel} />
              <Route exact path={"/TCGL_V_A2"} component={TCGL_V_A2} />
              <Route exact path={"/TCGL_V_A2/personal-details"} component={TCGL_V_A2_PersonalDetails} />
              <Route exact path={"/TCGL_V_A2/lenders"} component={TCGL_V_A2_Lenders} />
              <Route exact path={"/TCGL_V_A2/signature"} component={SignatureV7} />
              <Route exact path={"/TCGL_V_A2/credit-analysis"} component={TCGL_V_A2_CreditAnalysis} />
              <Route exact path={"/TCGL_V_A2/id-upload"} component={TCGL_V_A2_Id_Upload} />
              <Route exact path={"/edit-details-A2"} component={EditDetails_A2} />
              <Route exact path="/edit-credit-check-A2" component={CreditCheckEdit_A2} />
              <Route exact path={"/TCGL_V_A3"} component={TCGL_V_A3} />
              <Route exact path={"/TCGL_V_A3/personal-details"} component={TCGL_V_A3_PersonalDetails} />
              <Route exact path={"/TCGL_V_A3/lenders"} component={TCGL_V_A3_Lenders} />
              <Route exact path={"/TCGL_V_A3/signature"} component={SignatureV8} />
              <Route exact path={"/TCGL_V_A3/credit-analysis"} component={TCGL_V_A3_CreditAnalysis} />
              <Route exact path={"/TCGL_V_A3/id-upload"} component={TCGL_V_A3_Id_Upload} />
              <Route exact path={"/edit-details-A3"} component={EditDetails_A3} />
              <Route exact path="/edit-credit-check-A3" component={CreditCheckEdit_A3} />

            </BrowserRouter>
          </RedirectProvider>
        </QueryStringProvider>
      </VisitorParamsProvider>
    </>
  );
};

export default App;
