import React, {
  useContext,
  useEffect,
  useReducer,
  useRef,
  useState,
} from "react";
import logo from "../../../assets/img/TCG_L_D/logo.webp";
import bannerimg1 from "../../../assets/img/TCG_L_D/banner-img1.png";
// import Lenders from "../../Includes/Layouts/TCG_L_D/Lenders";
import PersonalDetails from "../../Includes/Layouts/TCG_L_D/PersonalDetails";
import LenderVerModal from "../../Includes/Layouts/TCG_L_D/LenderVerModal";
import Header from "../../Includes/Layouts/TCG_L_D/Header";
import AdditionalLenderModal from "../../Includes/Layouts/TCG_L_D/AdditionalLenderModal";
import Authorize from "../../Includes/Layouts/TCG_L_D/Authorize";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import VisitorParamsContext from "../../../Contexts/VisitorParams";
import LenderList from "../../Includes/Layouts/TCG_L_D/LenderList";
import steeringWheel from "../../../assets/img/TCG_L_D/steering-wheel.png";
import carClaim from "../../../assets/img/TCG_L_D/car-claim.png";
import fastTime from "../../../assets/img/TCG_L_D/fast-time.png";
import LogoBasedOnDomain from "../../Includes/Layouts/Common/LogoBasedOnDomain";
import QueryStringContext from "../../../Contexts/QueryString";
import * as EnvConstants from "../../../Constants/EnvConstants";
import { useAuthorizeStatus } from "../../../Hooks/useAuthorizeStatus";
import GTMDataLayer from "../../../Utility/GTMDataLayer";

const TCG_L_D_Form = (props) => {

  const { saveAuthorizeStatus } = useAuthorizeStatus();

  const initialState = {
    showSlide1: "show",
    showSlide2: "",
    showSlide3: "hide",
    showSlide4: "hide",
    showSlide5: "hide",
    showSlide6: "hide",
    showRadio: "hide",
    showLender: "hide",
    showDocument: "hide",
  };
  const FormReducer = (state, action) => {
    switch (action.type) {
      case "showSlide":
        if (action.payload.clickedSlide.slide === "formSubmitBtn") {
          props.setFooterEnable(false)
          return {
            ...state,
            showSlide1: "hide",
            showSlide2: "is-open",
          };
        }
        if (action.payload.clickedSlide.slide === "formSubmit") {
          props.setFooterEnable(false)
          return {
            ...state,
            showSlide2: "",
            showSlide3: "show",
          };
        }
        if (action.payload.clickedSlide.slide === "showSlide4") {
          props.setFooterEnable(false)
          return {
            ...state,
            showSlide3: "hide",
            showSlide4: "show",
            showRadio: "show",
          };
        }
        if (action.payload.clickedSlide.slide === "Yes") {
          props.setFooterEnable(false)
          return {
            ...state,
            showRadio: "hide",
            showLender: "show",
          };
        }
        if (action.payload.clickedSlide.slide === "No") {
          props.setFooterEnable(false)
          return {
            ...state,
            showSlide4: "hide",
            showSlide3: "block",
          };
        }
        if (action.payload.clickedSlide.slide === "showdocument") {
          props.setFooterEnable(false)
          return {
            ...state,
            showSlide5: "hide",
            showDocument: "show",
          };
        }
        if (action.payload.clickedSlide.slide === "next") {
          props.setFooterEnable(false)
          return {
            ...state,
            showLender: "hide",
            showSlide3: "block",
          };
        }
        if (action.payload.clickedSlide.slide === "skip") {
          props.setFooterEnable(false)
          return {
            ...state,
            showLender: "hide",
            showSlide3: "block",
          };
        }else{
          return {
            ...state,
          }
        }

        break;

      default:
        break;
    }
}


  const [state, disptach] = useReducer(FormReducer, initialState);
  const [clickedSlide, setClickedSlide] = useState("");
  const [banks, setBanks] = useState([]);
  const [remainingBanks, setRemainingBanks] = useState([]);
  const scrollToPc = useRef([]);
  const [authorize, setAuthorize] = useState("");
  const history = useHistory();
  const { visitorParameters } = useContext(VisitorParamsContext);
  const [addBank, setAddBank] = useState(false);
  const [validationError, setValidationError] = useState(false);
  const { queryString } = useContext(QueryStringContext);
  const field = "pid";


  useEffect(() => {
    if (state.showSlide3 == "show") {
      // setTimeout(() => {
        setClickedSlide({ slide: "showSlide4" });
        disptach({ type: "showSlide", payload: { clickedSlide } });
      // }, 3000);
    }
    if (state.showSlide3 == "block") {
      // setTimeout(() => {
        setClickedSlide({ slide: "showdocument" });
        disptach({ type: "showSlide", payload: { clickedSlide } });
      // }, 3000);
    }
  }, [state.showSlide3]);

  useEffect(() => {
    if (clickedSlide) {
      disptach({ type: "showSlide", payload: { clickedSlide } });
    }
  }, [clickedSlide]);

  const slideChange = (slideName) => {
    setClickedSlide({ slide: slideName });

    // DataLayer
    const slideNameMap = {
      "formSubmitBtn": "lenders",
      "formSubmit": "personal details",
      "Yes": "add lenders",
      "No": "add lenders"
    };
    const question = slideNameMap[slideName];
    if (question) {
      GTMDataLayer({
        clickEvent: 'slideclick',
        question,
        answer: '',
      });
    }
  };
  const authrizeSubmit = async () => {

    const AuthSaveResponse = await saveAuthorizeStatus(visitorParameters.visitor_parameters.uuid);

    const nextPage = "v4/car-registration";
      if (queryString != null) {
        if (queryString.indexOf("&" + field + "=") !== -1) {
          if (EnvConstants.AppConversionPixel === "true") {
            history.push(
              "/fbpixel?split_name=" +
              props.splitName +
              "&next_page=" + nextPage
            );
          } else {
            window.scrollTo(0, 0);
            history.push(
              "/" + nextPage +
              "?uuid=" +
              visitorParameters.visitor_parameters.uuid +
              "&splitName=" +
              props.splitName
            );
          }
        } else {
          window.scrollTo(0, 0);
          history.push(
            "/" + nextPage +
            "?uuid=" +
            visitorParameters.visitor_parameters.uuid +
            "&splitName=" +
            props.splitName
          );
        }
      } else {
        window.scrollTo(0, 0);
        history.push(
          "/" + nextPage +
          "?uuid=" +
          visitorParameters.visitor_parameters.uuid +
          "&splitName=" +
          props.splitName
        );
      }
  };

  return (
    <>
      <LenderVerModal show={state.showSlide3} />
      <Header />
      <section className="banner  ">
        <div className="container-fluid caption-top d-none">
          <p>
            Our Average Refund for NewDay as of 14/05/2024 is{" "}
            <strong> £1049</strong>
          </p>
        </div>
        <div className="container">
          <div>
            <div className="brand">
              <div className="logo pt-3">
                <a>
                <LogoBasedOnDomain height="40"/>
                </a>
              </div>
            </div>
          </div>
          <div className="container">
            <div className="banner-inner pb-5">
              <div className="row">
                <div className="col-lg-7 col-md-12 col-sm-12  ">
                  <div className="banner-caption">
                    <div className="overflow-hidden">
                      <h1 className="fw-bold  drop-in tex-lg-start text-md-start text-sm-center text-center">
                      Have you ever taken out a car on finance?
                      </h1>
                    </div>
                    <div className="overflow-hidden">
                      <p className="py-lg-3 py-md-3 py-sm-3 py-1 pt-0 drop-in-2 mb-0 mb-sm-3 subtext tex-lg-start text-md-start text-sm-center text-center">
                        You could be owed <strong>£1,000s</strong> in
                        compensation
                      </p>
                    </div>
                    <div className="d-block d-lg-none d-md-block d-sm-block py-1">
                      <div className="banner-car   text-lg-start text-md-center text-sm-center text-center mt-lg-0 mt-md-0 mt-sm-0 mt-2">
                        <div className="review drop-in-3  d-none">
                          <div className="overflow-hidden ">
                            <img
                              src="dist/img/review-star.png"
                              className="drop-in-3 "
                              alt=""
                            />
                          </div>
                        </div>
                        <img
                          className=" car-img fade-in"
                          src={bannerimg1}
                          alt=""
                        />
                      </div>
                    </div>
                    <LenderList
                      slideChange={slideChange}
                      setBanks={setBanks}
                      setRemainingBanks={setRemainingBanks}
                      remainingBanks={remainingBanks}
                      setValidationError={setValidationError}
                      validationError={validationError}
                    />
                  </div>
                </div>
                <div className="col-lg-5 col-md-12 col-sm-12  d-lg-block d-md-none d-none">
                  <div className="bannerimage-wraper text-lg-start text-md-center text-sm-center text-center mt-lg-0 mt-md-0 mt-sm-0 mt-2">
                    <div className="review drop-in-3 d-none">
                      <div className="overflow-hidden ">
                        <img
                          src="dist/img/review-star.png"
                          className="drop-in-3 "
                          alt=""
                        />
                      </div>
                    </div>
                    <img className="bannerimg fade-in" src={bannerimg1} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="welcome-highlights py-5">
        <div className="container">
          <div className="row">
            <div className="col-lg-4 col-md-4 col-sm-12 text-center mb-3">
              <div className="px-lg-3 px-md-0 px-sm-0 px-0 items">
                <img height={70} src={steeringWheel} alt="" />
                <h2 className="mt-3">
                  Millions of UK drivers could be eligible for refunds
                </h2>
              </div>
            </div>
            <div className="col-lg-4 col-md-4 col-sm-12 text-center mb-3">
              <div className="px-lg-3 px-md-0 px-sm-0 px-0 items">
                <img height={70} src={carClaim} alt="" />
                <h2 className="mt-3">
                  You can claim for current and past vehicles
                </h2>
              </div>
            </div>
            <div className="col-lg-4 col-md-4 col-sm-12 text-center mb-3">
              <div className="px-lg-3 px-md-0 px-sm-0 px-0 items">
                <img height={70} src={fastTime} alt="" />
                <h2 className="mt-3">Start your claim in just 60 seconds</h2>
              </div>
            </div>
          </div>
        </div>
      </section>
      <PersonalDetails
        showSlide={state.showSlide2}
        scrollToPc={scrollToPc}
        setModal={props.setModal}
        slideChange={slideChange}
        banks={banks}
        setAddBank={setAddBank}
        validationError={validationError}
        splitName={props.splitName}
      />
      {addBank && (
        <AdditionalLenderModal
          show={state.showSlide4}
          show5={state.showSlide5}
          showRadio={state.showRadio}
          showLender={state.showLender}
          slideChange={slideChange}
          banks={banks}
          remainingBanks={remainingBanks}
          uuid={visitorParameters.visitor_parameters.uuid}
          authrizeSubmit={authrizeSubmit}
        />
      )}

      <Authorize
        show={state.showDocument}
        setAuthorize={setAuthorize}
        authrizeSubmit={authrizeSubmit}
      />
    </>
  );
};
export default TCG_L_D_Form;
