import { useState, useEffect, useContext } from "react";
import "../../../../assets/css/TCGL_V_A1/credit-analysis.scss";
import "../../../../assets/css/TCGL_V_A1/custom.scss";
import sslPng from "../../../../assets/img/TCGL_V_A1/ssl-png.png";
import searching from "../../../../assets/img/TCGL_V_A1/searching.gif";
import analysisIcon from "../../../../assets/img/TCGL_V_A1/analysis-icon.png";
import RadioButton from "../../../UI/RadioButton";
import { useGetCreditAnalysisData_Checkboard } from "../../../../Hooks/useGetCreditAnalysisData";
import VisitorParamsContext from "../../../../Contexts/VisitorParams";
import GetVisitorsParams from "../../../../Utility/GetVisitorsParams";
import { useHistory } from "react-router-dom";
import { getQueryString } from "../../../../Utility/QueryString";
import LogoBasedOnDomain from "../../../Includes/Layouts/Common/LogoBasedOnDomain";
import GTMDataLayer from "../../../../Utility/GTMDataLayer";
import { CheckUUID } from "../../../../Utility/CheckUUID";
import { handleCreditCheckFailureFollowup, fetchCreditCheckBasicData } from "../../../../Utility/Validations";

let creditCheckFailedNextPage = null;
const FollowUpCreditAnalysis = () => {
  const [questionData, setQuestionData] = useState('');
  const { isCheck } = CheckUUID();
  const [progress, setProgress] = useState({ question_intervel: [0], analysis_intervel: [0], analysis_intervel_content: { 0: "Checking your finance agreement..." } });
  const [currentProgress, setCurrentProgress] = useState(0);
  const [endProgress, setEndProgress] = useState(10);
  const [creditCheckFailed, setCreditCheckFailed] = useState(false);
  const [formSubmitSuccess, setFormSubmitSuccess] = useState(false);
  const { getCreditAnalysisData } = useGetCreditAnalysisData_Checkboard()
  const { visitorParameters } = useContext(VisitorParamsContext);
  let formData = JSON.parse(localStorage.getItem('formData')) || {};
  const [userAnswers, setUserAnswers] = useState([]);
  const history = useHistory();
  const queryString = getQueryString();
  const splitName = new URLSearchParams(queryString).get('splitName');
  const query_uuid = new URLSearchParams(queryString).get("uuid");
  const currentPage = "followup-credit-analysis-v2";
  const atp_sub2 = new URLSearchParams(queryString).get('atp_sub2') ? new URLSearchParams(queryString).get('atp_sub2'):localStorage.getItem('atp_sub2') ;
  // const nextPage = 'TCGL_V_A1/creditcheck-lenders';
  const nextPage = 'followup-loader';
  const [otherCreditDetails, setOtherCreditDetails] = useState('');
  const uuid = visitorParameters.visitor_parameters.uuid ?? localStorage.getItem("uuid") ?? new URLSearchParams(queryString).get("uuid");
  if(!uuid){
    isCheck();

  }
  const atp_sub6 = new URLSearchParams(queryString).get('atp_sub6') ? new URLSearchParams(queryString).get('atp_sub6'):localStorage.getItem('atp_sub6') ;

  let pendingArrayData = localStorage.getItem("pendingArrayData");
  pendingArrayData = pendingArrayData ? JSON.parse(pendingArrayData) : {};
  pendingArrayData.is_credit_check = 1;
  const updatedDetails = JSON.stringify(pendingArrayData);
  let pendingLocalData = localStorage.getItem("pendingLocalData");
  pendingLocalData = pendingLocalData ? JSON.parse(pendingLocalData) : {};
  pendingLocalData.is_credit_check = 1;
  const updatedLocalDetails = JSON.stringify(pendingLocalData);
  let userInfo = JSON.parse(localStorage.getItem("user_info"));
  useEffect(() => {
    (async () => {
      if (!formData.first_name && !formData.last_name) {
        formData = fetchCreditCheckBasicData(userInfo);
        localStorage.setItem("formData", JSON.stringify(formData));
      }
      formData['source'] = atp_sub6;
      const res = await getCreditAnalysisData({ uuid: query_uuid }, formData, 'get_credit_check_questions');
      if (res.data.status == 'Success' && res?.data?.response?.vendor === 'checkboard') {
        if (res?.data?.response?.questions.length > 0) {
          const questionApiData = res?.data?.response?.questions;
          let questionIntervel = [0];
          let analysisIntervel = [0];
          let quest_int = Math.round(90 / questionApiData.length);
          for (let i = 1; i < (questionApiData.length + 1); i++) {
            let q_int = quest_int * i;
            questionIntervel.push(q_int);
          }
          let analysis_int = Math.round(quest_int / 2);
          for (let j = 1; j < ((questionApiData.length + 1) * 2); j++) {
            let a_int = analysis_int * j;
            analysisIntervel.push(a_int);
          }
          let analysisContent = ["Checking your finance agreement...", "Checking your finance agreement...", "Reviewing your PCP details...", "Verifying your car finance information...", "Analyzing your PCP contract...", "Processing your claim eligibility..."];
          let analysisIntervelContent = {}
          analysisIntervel.forEach((value, index) => {
            analysisIntervelContent[value] = analysisContent[index] || analysisContent[analysisContent.length - 1];
          });

          setProgress({ question_intervel: questionIntervel, analysis_intervel: analysisIntervel, analysis_intervel_content: analysisIntervelContent })
          setQuestionData(questionApiData);
          setOtherCreditDetails({ referenceId: res?.data?.response?.referenceId })
        }
      } else {
        // history.push("/" + nextPage + "?uuid=" + visitorParameters.visitor_parameters.uuid + "&splitName=" + splitName);
        setFormSubmitSuccess(true);
        setCreditCheckFailed(true);
      }
    })();
  }, []);

  useEffect(() => {
    if (questionData) {
      setCurrentProgress(currentProgress + 1);
      setEndProgress(progress?.question_intervel?.[1] || endProgress);
      const updatedAnalysisIntervelNew = progress?.analysis_intervel?.slice(1);
      const updatedQuestionIntervelNew = progress?.question_intervel?.slice(1);
      setProgress((prevState) => ({
        ...prevState,
        question_intervel: updatedQuestionIntervelNew,
        analysis_intervel: updatedAnalysisIntervelNew,

      }));
    }
  }, [questionData]);

  useEffect(() => {
    if (!creditCheckFailed) {
      if (currentProgress < endProgress) {
        const timer = setInterval(() => {
          setCurrentProgress(currentProgress + 1);
          if ((currentProgress + 1) > progress?.analysis_intervel?.[0]) {
            const updatedAnalysisIntervel = progress.analysis_intervel.slice(1);
            setProgress((prevState) => ({
              ...prevState,
              analysis_intervel: updatedAnalysisIntervel
            }));
          }
        }, 200);
        return () => clearInterval(timer);
      }
    } else {
      const timer = setInterval(() => {
        setCurrentProgress(currentProgress + 1);
      }, 50);
      return () => clearInterval(timer);
    }
  }, [currentProgress, creditCheckFailed]);
  const radioBtnClick = async (e) => {
    if (e.target.name == "question_1") {
      GTMDataLayer({
        clickEvent: 'slideclick',
        question: 'account provider',
        answer: '',
      });

    }
    if (e.target.name == "question_2") {
      GTMDataLayer({
        clickEvent: 'slideclick',
        question: 'credit card provider',
        answer: '',
      });
    }
    if (e.target.name == "question_3") {
      GTMDataLayer({
        clickEvent: 'slideclick',
        question: 'recieve date',
        answer: '',
      });

    }
    let userSelectedAnswer = {
      'questionId': parseInt(e.target.name.split('_')[1]),
      'answer': [parseInt(e.target.value)]
    }
    setUserAnswers((prevUserAnswer) => ([
      ...prevUserAnswer,
      userSelectedAnswer
    ]));
    let optionName = questionData?.[0]?.choices.find(choice => choice.choiceId == e.target.value).choiceText;
    const res = await getCreditAnalysisData(visitorParameters.visitor_parameters, { ...userSelectedAnswer, 'optionName': optionName }, 'credit_check_question_store');
    let newQuestionSet = questionData.slice(1);
    setQuestionData(newQuestionSet);
    if (newQuestionSet.length == 0) {

      setCurrentProgress(currentProgress + 1);
      setEndProgress(100);
      let data = { question_answers: [...userAnswers, userSelectedAnswer] };
      data.referenceId = otherCreditDetails.referenceId;
      formData.source = atp_sub6;

      data.formData = formData;
      const res = await getCreditAnalysisData(visitorParameters.visitor_parameters, data, 'credit_check_answers');

      // localStorage.setItem('creditCheckApiStatus', res.data.status);
      const valid = handleCreditCheckFailureFollowup();
      if (valid == 1) {
        creditCheckFailedNextPage = true;
        // nextPage = 'edit-details';
      } else {
        creditCheckFailedNextPage = false;
        // nextPage = 'edit-details';
      }
      setFormSubmitSuccess(true);
    }
  }

  useEffect(() => {
    if (currentProgress == 100 && formSubmitSuccess) {
      localStorage.setItem("pendingArrayData", updatedDetails);
      localStorage.setItem("pendingLocalData", updatedLocalDetails);
      localStorage.setItem("current_page", currentPage);

      if (creditCheckFailedNextPage === true) {
        history.push("/try_again?uuid=" + "?uuid=" + uuid + "&splitName=" + splitName + "&atp_sub2=" + atp_sub2 + "&nextPage=followup-id-upload-v2");
      } else if (creditCheckFailedNextPage === false) {
        history.push("/success?uuid=" + "?uuid=" + uuid + "&splitName=" + splitName + "&atp_sub2=" + atp_sub2 + "&nextPage=followup-id-upload-v2" );
      } else {
        history.push("/" + nextPage + "?uuid=" + uuid + "&splitName=" + splitName + "&atp_sub2=" + atp_sub2 + "&atp_sub6=" + atp_sub6);
      }
    }
  }, [currentProgress, formSubmitSuccess])
  // useEffect(() => {
  //   if (currentProgress == 100 && formSubmitSuccess) {
  //     localStorage.setItem("pendingArrayData", updatedDetails);
  //     localStorage.setItem("pendingLocalData", updatedLocalDetails);
  //     localStorage.setItem("current_page", currentPage);
  //     history.push("/followup-loader?uuid=" + uuid + "&current_page=" + currentPage + "&atp_sub2=" + atp_sub2
  //     );
  //   }
  // }, [currentProgress,formSubmitSuccess])
  return (
    <>
      <GetVisitorsParams />
      <div className="TCGL_V_A1_creditanalysis TCGL_V_A1">
        <div className="supagebg">
          <header className="credheader">
            <div className="container">
              <div className="row">
                <div className="col-12 text-center">
                  <a >
                    {/* <img className="header-logo" src={LogoWhite} height="45" /> */}
                    <LogoBasedOnDomain className="header-logo" bg="white" height={45} />
                  </a>
                </div>
              </div>
            </div>
          </header>
          <section className="creditanalysis">
            <div className="container">
              <div className="row">
                <div className="tryhide">
                  <div className="col-lg-6 col-12 offset-lg-3">
                    <div className="nobg d-flex align-items-center mt-3">
                      <div className="col-lg-2 col-3 text-center">
                        <img src={analysisIcon} height="50" alt="" />
                      </div>
                      <div className="col-lg-10 col-9 bor-left-cred  align-self-center">
                        <h4>Please wait while we locate your car finance agreements</h4>
                      </div>
                    </div>
                    <div className="col-12 text-center">
                      <div className="progress-container mt-3">
                        <div className="progress-circle" style={{
                          background: `conic-gradient(#1DD9B3 ${(currentProgress / 100) * 360}deg, #1732a2 ${(currentProgress / 100) * 360}deg)`,
                        }} id="progress-circle">
                          <div className="progress-inner">
                            <div>
                              <span id="progress-value" className="fw-bold">{currentProgress}%</span>
                              <div className="text2">COMPLETED</div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="drop-in col-12 crediatailsloader text-center">
                    {(currentProgress < endProgress || endProgress == 100 || endProgress == 10) ? (
                      <div className="cred-slide1">
                        <div className="mb-2">
                          <img src={searching} height="100" alt="" />
                        </div>
                        <div>
                          <img src={sslPng} height="50" alt="" />
                        </div>
                      </div>
                    ) : (
                      <div className="drop-in col-12 col-lg-8 offset-lg-2">
                        <h4>{questionData?.[0]?.questionText}</h4>
                        {questionData && questionData[0] && questionData[0].choices.map((item, index) => (
                          <RadioButton
                            name={`question_${questionData[0].questionId}`}
                            id={`question_${questionData[0].questionId}_${item.choiceId}`}
                            value={item.choiceId}
                            onClick={radioBtnClick}
                            labelClassName={`cred-but`}
                            spanclassName=""
                            labelName={item.choiceText}
                          />
                        ))}
                        <div className="col-12">
                          <img src={sslPng} height="50" alt="" />
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
    </>
  )
}

export default FollowUpCreditAnalysis;