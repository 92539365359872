import { useContext, useEffect, useRef, useState } from "react";
import Header from "../../Includes/Layouts/TCG_L_B/Header";
import upload from "../../../assets/img/upload.gif";
import upload_demo from "../../../assets/img/upload-demo.jpg";
import loader03 from "../../../assets/img/loader03.svg";
import GetVisitorsParams from "../../../Utility/GetVisitorsParams";
import { useForm } from "react-hook-form";
import { useVehicleValidation } from "../../../Hooks/useVehicleValidation";
import { useSkipCarRegisterValidation } from "../../../Hooks/useSkipCarRegisterValidation";
import VisitorParamsContext from "../../../Contexts/VisitorParams";
import { useHistory } from "react-router-dom";
import QueryStringContext from "../../../Contexts/QueryString";
import FaqAccordion from "../../Includes/Layouts/TCG_L_B/FaqAccordion";
import Footer from "../../Includes/Layouts/TCG_L_B/Footer";
import { Api } from "../../../api/Api";
import { useIdUpload } from "../../../Hooks/useIdUpload";
import { DynamicRoutingSplit } from "../../../Utility/DynamicRoutingSplit";
import { useUserInfo } from "../../../Hooks/useUserInfo";
import Loader from "../../Includes/Layouts/Common/Loader";
import AdditionalLender from "../../Includes/Layouts/TCG_L_B/AdditionalLender";
import GetLenders from "../../../Utility/GetLenders";
import TextField from "../../UI/TextField";
import Button from "../../UI/Button";
import InputFile from "../../UI/InputFile";
import GTMDataLayer from "../../../Utility/GTMDataLayer";
import { useSkipIdUpload } from "../../../Hooks/useSkipIdUpload";

const CarRegistraionV2 = () => {
  const page_name = "pcp/TCG_L_B";
  const { DynamicRouteSplitName, DynamicRouteNextPage } = DynamicRoutingSplit(
    "TCG_L_B",
    "v2/car-registration"
  );
  const [vehicleNumberInput, setVehicleNumberInput] = useState("");
  const [keeperLatest, setKeeperLatest] = useState(null);
  const [formattedDates, setFormattedDates] = useState([]);
  const [vehicleDateModal, setVehicleDateModal] = useState(false);
  const [selectedBanks, setSelectedBanks] = useState([]);
  const [modalCount, setModalCount] = useState(0);
  const [currentModalCount, setCurrentModalCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(0);
  const [vehicleSubmitLoader, setVehicleSubmitLoader] = useState("");
  const [requiredValidationResponseData, setRequiredValidationResponseData] =
    useState({
      vehicle_year: "",
      vehicle_model: "",
      vehicle_make: "",
    });
  const [loaderStatus, setLoaderStatus] = useState(true);
  const type = "car_registration";
  const [formData, setFormData] = useState([]);
  const [loader1, setLoader1] = useState(false);

  const { isLoading, validateRegNum, storeVehicleDetails } =
    useVehicleValidation();
  const { skipRegistrationValidation } = useSkipCarRegisterValidation();
  const { skipidupload } = useSkipIdUpload();
  const { visitorParameters } = useContext(VisitorParamsContext);
  const { queryString } = useContext(QueryStringContext);
  const uuid =
    visitorParameters.visitor_parameters.uuid ?? localStorage.getItem("uuid");

  const query = new URLSearchParams(window.location.search);
  const query_uuid = query.get("uuid");
  const history = useHistory();
  const { getBankInfo } = useUserInfo();
  const [currentBankIndex, setCurrentBankIndex] = useState(0);
  const [year, setVehicleYear] = useState("");
  const [vehicle, setVehicle] = useState("");
  const [model, setVehicleModel] = useState("");
  const [edition, setEdition] = useState("");
  const [selectedDate, setSelectedDate] = useState(null);
  const findRegRef = useRef(null);
  const [show, setShow] = useState(true);
  const [show1, setShow1] = useState(true);
  const { getLenderList } = GetLenders();
  const split_name = "pcp/TCG_L_B";

  const { register, errors, trigger, setError, clearErrors, getValues } =
    useForm({
      mode: "onBlur",
      reValidateMode: "onChange",
      defaultValues: {},
      resolver: undefined,
      context: undefined,
      criteriaMode: "firstError",
      shouldFocusError: true,
      shouldUnregister: true,
    });

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const handleNextButtonClick = async () => {
    const vehicleNumber = getValues("vehicle_number");
    const bank = formData[currentBankIndex];

    await trigger("vehicle_number");
    if (errors.vehicle_number) return;
    try {
      // setShowSpinner(true);
      const validationResponse = await validateRegNum(
        getValues("vehicle_number"),
        uuid,
        page_name
      );
      if (validationResponse && validationResponse.data.keeper_date) {
        if (
          validationResponse.status === 200 &&
          validationResponse.data.is_valid === 1
        ) {
          // Validation successful
          clearErrors("vehicle_number"); // Clear vehicle number error if any
          clearErrors("selected_value"); // Clear vehicle number error if any
        } else {
          setError("vehicle_number", { message: "Enter valid vehicle number" });
        }
        const keeperDatesObj = JSON.parse(validationResponse.data.keeper_date);
        // Convert the response to a string before storing it in localStorage
        const responseString = JSON.stringify(validationResponse.data);

        // Store the response in localStorage
        localStorage.setItem("validationResponse", responseString);

        const storedResponseString = localStorage.getItem("validationResponse");

        // Convert the stored JSON string back to an object
        const storedValidationResponse = JSON.parse(storedResponseString);
        setVehicle(storedValidationResponse.car_reg_no);
        setVehicleYear(storedValidationResponse.vehicle_year);
        setVehicleModel(storedValidationResponse.vehicle_model);
        setEdition(storedValidationResponse.vehicle_make);

        if (keeperDatesObj && keeperDatesObj.keeper_dates) {
          const keeperDates = keeperDatesObj.keeper_dates;

          const latestDate = new Date(
            Math.max(...keeperDates.map((date) => new Date(date)))
          );

          const nextDayDate = new Date(latestDate);
          nextDayDate.setDate(latestDate.getDate() + 1);

          const nextDayDateString = nextDayDate.toISOString().split("T")[0];

          setKeeperLatest(nextDayDateString);
        }
      }

      if (
        validationResponse.status === 200 &&
        validationResponse.data.is_valid === 1
      ) {
        const keeperDateStr = validationResponse.data.keeper_date;
        const keeperDateObj = JSON.parse(keeperDateStr);

        // Extract the array of keeper dates
        const keeperDates = keeperDateObj.keeper_dates;

        // Convert each keeper date to the desired format
        const formattedDatesArray = keeperDates.map((dateStr) => {
          const parts = dateStr.split(" "); // Split the date string by space
          const month = parts[1];
          const year = parts[2];
          const formattedDate = `${month} ${year}`;
          return { formattedDate, originalDate: dateStr }; // Store both formatted and unformatted dates
        });

        formattedDatesArray.push({
          formattedDate: "Other",
          originalDate: "other",
        });

        // Set the formatted dates in the state variable
        setFormattedDates(formattedDatesArray);

        setVehicleDateModal(true);
        setTimeout(() => {
          findRegRef.current.scrollIntoView({
            behavior: "smooth",
            block: "end",
          });
        }, 100);

        //GTMDataLayer trigger on car reg next btn click
        GTMDataLayer({
          clickEvent: 'slideclick',
          question: 'car reg number',
          answer: '',
        });

      } else {
        setError("vehicle_number", { message: "Enter valid vehicle number" });
      }
    } catch (error) {
      console.error("Error validating car registration: ", error);
      setError("vehicle_number", { message: "Network error" });
    } finally {
      // setShowSpinner(false);
    }
  };

  const skipCarRegisterValidationApi = async (
    query_uuid,
    page_name,
    type,
    bank_id
  ) => {
    try {
      await skipRegistrationValidation(query_uuid, page_name, type, bank_id);
    } catch (error) {
      console.log(error);
    }
  };

  const skipThisPage = () => {
    setLoaderStatus(true);
    setLoader1(true);
    setTimeout(() => {
      setLoaderStatus(false);
      (async () => {
        const response = await getLenderList();
      })();
    }, 2000);
    const bank = formData[currentBankIndex]; // Get the current bank
    let bank_id = bank.id;
    console.log("bank_id",bank_id);
    skipCarRegisterValidationApi(query_uuid, page_name, type, bank_id);
    if (currentBankIndex < formData.length - 1) {
      setCurrentBankIndex(currentBankIndex + 1);
    } else {
      setCurrentPage(1);
      // history.push(
      //   "/" +
      //     DynamicRouteNextPage +
      //     "?uuid=" +
      //     visitorParameters.visitor_parameters.uuid +
      //     "&split_name=" +
      //     DynamicRouteSplitName
      // );
    }
    setVehicleNumberInput('');
  };
   //skip btn functionality
  const skipIdPage = async() => {
    const typeTwo = "id_upload";
    const bank_id = "";
    const page_name = "pcp/TCG_L_B";
    const response = await skipidupload(
      uuid,
      page_name,
      "id_upload",
    );
    // history.push(`/thankyou?${query.toString()}`);
    history.push("/" + DynamicRouteNextPage + "?uuid=" + visitorParameters.visitor_parameters.uuid + "&splitName=" + split_name);
  };

  const vehicleModalClick = async (value) => {
    setSelectedDate(value);
    setVehicleSubmitLoader(value);
    const vehicleNumber = getValues("vehicle_number");
    let flag = selectedBanks.length;
    const bank = formData[currentBankIndex];
    let vehicle_data = {
      yourVehicle: "",
      carRegNo: vehicle,
      carYear: year,
      carModel: model,
      carMake: edition,
      pageName: page_name,
      question_option_id: value,
      question_id: 4,
      bank_id: bank.id,
    };

    // const formdata = {
    //   page_name: page_name,
    // };

    const response = await storeVehicleDetails(
      visitorParameters.visitor_parameters,
      visitorParameters.data,
      JSON.parse(localStorage.getItem("formData")),
      queryString,
      vehicle_data,
      "store-vehicle-details",
      page_name
    );
    if (currentBankIndex < formData.length - 1) {
      setCurrentBankIndex(currentBankIndex + 1);
    } else {
      setCurrentPage(1);
      // history.push(
      //   "/" +
      //     DynamicRouteNextPage +
      //     "?uuid=" +
      //     visitorParameters.visitor_parameters.uuid +
      //     "&split_name=" +
      //     DynamicRouteSplitName
      // );
    }

    setCurrentModalCount(currentModalCount + 1);

    setVehicleNumberInput("");
    document.getElementById('vehicle_number').value = '';

    setTimeout(() => {
      setVehicleDateModal(false);
      setVehicleSubmitLoader("");
    }, 2000);

    const radios = document.getElementsByName("Radioshere2");
    radios.forEach((radio) => {
      radio.checked = false;
    });

    if (currentModalCount + 1 == modalCount) {
      setCurrentPage(currentPage + 1);
    }
  };
  // useEffect(() => {
  //   const fetchData = async () => {
  //     let storedSelectedBanks =
  //       JSON.parse(localStorage.getItem("selectedBanks")) || [];
  //     let storedAdditionalBanks =
  //       JSON.parse(localStorage.getItem("additionalBanks")) || [];

  //     if (storedSelectedBanks.length === 0) {
  //       const response = await getBankInfo(query_uuid);
  //       setFormData(response.data.response);
  //     } else {
  //       let mergedBanks = [...storedSelectedBanks];
  //       storedAdditionalBanks.forEach((additionalBank) => {
  //         if (
  //           !mergedBanks.find(
  //             (bank) => bank.bank_code === additionalBank.bank_code
  //           )
  //         ) {
  //           mergedBanks.push(additionalBank);
  //         }
  //       });
  //       setFormData(mergedBanks);
  //     }
  //   };

  //   if (!show1) {
  //     fetchData();
  //   }
  // }, [show1]);

  useEffect(() => {
    setTimeout(() => {
      setLoaderStatus(false);
      (async () => {
        const response = await getLenderList();
      })();
    }, 2000);

    const fetchData = async () => {
      let storedSelectedBanks =
        JSON.parse(localStorage.getItem("selectedBanks")) || [];
      let storedAdditionalBanks =
        JSON.parse(localStorage.getItem("additionalBanks")) || [];

      if (storedSelectedBanks.length === 0) {
        const response = await getBankInfo(query_uuid);
        setFormData(response.data.response);
      } else {
        let mergedBanks = [...storedSelectedBanks];
        storedAdditionalBanks.forEach((additionalBank) => {
          if (
            !mergedBanks.find(
              (bank) => bank.bank_code === additionalBank.bank_code
            )
          ) {
            mergedBanks.push(additionalBank);
          }
        });
        setFormData(mergedBanks);
      }
    };

    fetchData();

    
  }, [query_uuid,show1]);

  //////////////
  // IdUpload //
  /////////// //
  const [idUploadCurrentSlide, setIdUploadCurrentSlide] = useState(0);
  const [idType, setIdType] = useState("");
  const [isInValidfFile, setIsInValidfFile] = useState(false);
  const [errorMessage, setErrorMessage] = useState();
  const [userfile, setUserfile] = useState({ preview: "", title: "" });
  const [base64Image, setBase64Image] = useState();
  const [progressbar, setProgressBar] = useState(false);
  const [selectedFileType, setSelectedFileType] = useState(false);
  const [btnLoader, setBtnLoader] = useState(false);
  const currentBank = formData[currentBankIndex];

  const { saveIdUpload } = useIdUpload();

  const handleNextUploadClick = (e) => {
    setIdType(e.target.id);
    setIdUploadCurrentSlide(1);
  };

const handleBlur = (e) => {
  // e.target.value = e.target.value.replace(/[^a-zA-Z0-9]/g, '');
  e.target.value = e.target.value.replace(/[^a-zA-Z0-9\s]/g, '')
};
  const handleFileChange = (event) => {
    setProgressBar(true);
    const HandleChangeTimeout = setTimeout(() => {
      let imageFile = event.target.files[0];
      if (imageFile) {
        let extension = event.target.files[0].type;
        let imgsize = event.target.files[0].size;
        let allowedExtensions = ["image/jpeg", "image/png"];
        if (allowedExtensions.indexOf(extension) === -1) {
          setIsInValidfFile(true);
          setShow(true);
          setUserfile({ preview: "", title: "" })
          setProgressBar(false)
          setErrorMessage({
            txt: "The uploaded ID type should be an image in jpeg,png or jpg",
          });
          return false;
        } else if (imgsize > 1024 * 1024 * 20) {
          setIsInValidfFile(true);
          setErrorMessage({ txt: "Please choose a file less than 20mb" });
          return false;
        } else {
          setSelectedFileType(event.target.name);
          setIsInValidfFile(false);
        }

        setUserfile({
          preview: URL.createObjectURL(imageFile),
          title: imageFile.name,
        });
        if (imageFile) {
          let reader = new FileReader();
          reader.onload = _handleReaderLoaded.bind(this);
          reader.readAsDataURL(imageFile);
        }
      } else {
        setIsInValidfFile(true);
        setUserfile({ preview: "", title: "" });
      }
      setProgressBar(false);
    }, 2000);
    setShow(false);
  };

  const _handleReaderLoaded = (readerEvt) => {
    let binaryString = readerEvt.target.result;
    setBase64Image({
      base64TextString: binaryString,
    });
  };

  const handleIDuploadButtonClick = (e) => {
    setErrorMessage(false);
    if (e.target.name == "photo") {
      document.getElementById(`${idType}_cam`).click();
    }
    if (e.target.name == "image") {
      document.getElementById(`${idType}_doc`).click();
    }
  };
  const retakeClick = (e) => {
    setUserfile({ preview: "", title: "" });
    setShow(true);
    // setIsFileSelected(false);
    // setSelectedFile('');
  };
  const handleBackbutton = () => {
    setIdUploadCurrentSlide(0);
    // setIsFileSelected(false);
    // setSelectedFileType('');
    setUserfile({ preview: "", title: "" });
    setBase64Image("");
    setSelectedFileType("");
    setErrorMessage("");
    setShow(true);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setBtnLoader(true);
    const formParameters = JSON.parse(localStorage.getItem("formData"));
    formParameters.page_name = localStorage.getItem("split_name");
    let uploadData = "";
    if (userfile.preview !== "") {
      uploadData = {
        imageFile: base64Image.base64TextString,
        captureType: selectedFileType.split("_")[0],
        documentType: selectedFileType.split("_")[0],
      };
      // let providers = '';
      const response = await saveIdUpload(
        uploadData,
        visitorParameters.visitor_parameters,
        formParameters,
        visitorParameters.data,
        // providers,
        queryString,
        "user_docs_store"
      );
      // setSelectedFile('');
      setBase64Image("");
      setSelectedFileType("");

      // GTMDataLayer trigger on id submit btn
      GTMDataLayer({
        clickEvent: 'slideclick',
        question: 'verify identity',
        answer: '',
      });

      history.push(
        "/" +
          DynamicRouteNextPage +
          "?uuid=" +
          visitorParameters.visitor_parameters.uuid +
          "&splitName=" +
          split_name
      );
      // history.push(`/thankyou?${query.toString()}`);
    } else {
      setBtnLoader(false);
      setIsInValidfFile(true);
      setErrorMessage({ txt: "Please upload a file" });
    }
  };
 const Yesfinanceclick  = async () =>  {
  setVehicleDateModal(false);
  setVehicleNumberInput("");
  document.getElementById("vehicle_number").value='';

  const bank = formData[currentBankIndex];
  let vehicle_data = {
    yourVehicle: "",
    carRegNo: vehicle,
    carYear: year,
    carModel: model,
    carMake: edition,
    pageName: page_name,
    question_option_id: '8',
    question_id: 4,
    bank_id: bank.id,
  };

  const response = await storeVehicleDetails(
    visitorParameters.visitor_parameters,
    visitorParameters.data,
    JSON.parse(localStorage.getItem("formData")),
    queryString,
    vehicle_data,
    "store-vehicle-details",
    page_name
  );
};

  return (
    <>
      <GetVisitorsParams />
      <div className="TCG_L_B">
        {loaderStatus && <Loader loderContent={`${loader1 ?' ':'Analysing Your Result...'}`}/>}
        {!loaderStatus && (
          <div id="signaturesect">
            <Header />
            <section className="dashboad-bottom  ">
              <div className="inner  pt-3 pb-5">
                <div className="container">
                  <span ref={findRegRef} tabIndex={0}></span>
                  <div
                    className={`slide6 ${
                      currentPage === 0 ? "d-block" : "d-none"
                    }`}
                  >
                    <div className="wrap-number  ">
                      <h1 className=" mt-2  mb-4 ">
                        Enter your Car Registration Number you have with your
                        Lender{" "}
                        <span className="fw-bold lendername">
                          {currentBank && `${currentBank.label}`}
                        </span>
                      </h1>
                      <div className="mb-3   ">
                        <div className="d-flex  number-plate">
                          <div className="flag"> </div>
                          <div className="input-wrap">
                            <TextField
                             onKeyUp={handleBlur}
                            //  onkeypress={(e) => carnovalidate(e)}
                             onBlur={handleBlur}
                              className="form-control w-100"
                              type="text"
                              name="vehicle_number"
                              id="vehicle_number"
                              maxlength="13"
                              autocomplete="off"
                              placeholder="Enter Vehicle Number"
                              value={vehicleNumberInput}
                              onChange={(e) => {
                                setVehicleNumberInput(e.target.value.replace(/\s/g, ''));
                                clearErrors("vehicle_number");
                              }}
                              validation={register({
                                required: "Please Enter Vehicle Number",
                                // maxLength: {
                                //   value: 9,
                                //   message: "Please Enter Valid Vehicle Number",
                                // },
                              })}
                            />
                          </div>
                          {/* <span class="add-more fw-bold" id="addNUmber"> Add  </span> */}
                        </div>
                        {errors.vehicle_number && (
                          <span className="error_msg">
                            {errors.vehicle_number.message}
                          </span>
                        )}
                      </div>
                      <p cl="">
                        You are eligible for a claim even if you don't currently
                        have the car or an active finance agreement.
                      </p>
                      <div className="text-center pb-2 ">
                        <Button
                          id="lender"
                          className="pulse btn-comm p-2 w-100 mb-lg-0 mb-md-0 mb-sm-0 mb-2 py-3 progress-button"
                          fdprocessedid="nqujd"
                          onClick={handleNextButtonClick}
                          buttonText={
                            <span className="fw-bold">
                              Next{" "}
                              <i className="arrowicon">
                                {" "}
                                <i className="bi bi-chevron-right" />
                              </i>
                            </span>
                          }
                        />
                      </div>
                      <div className="mt-lg-3 mt-md-3 mt-sm-2 mt-1 text-center">
                        <Button
                          onClick={() => skipThisPage()}
                          className="skip-btn"
                          id="skipLender"
                          buttonText="Skip"
                        />
                      </div>
                    </div>
                  </div>
                  <div
                    className={`slide7 uploads-section ${
                      currentPage === 1 ? "d-block" : "d-none"
                    }`}
                  >
                    <div>
                      <h1 className="fw-bold my-3    mt-lg-5 mt-md-5 mt-sm-4 mt-4 ">
                        {" "}
                        Now, let's verify your identity
                      </h1>
                      <h5>
                      Your ID will be stored securely. We will use the documents provided for identity verification and claim progression.
                      </h5>
                      {/*  Id Uploads */}
                      <div className="mt-4 mb-3 animate__animated animate__fadeInUp">
                        <div
                          className={`uploadbtns ${
                            idUploadCurrentSlide !== 0 && "d-none"
                          }`}
                        >
                          <Button
                            className="uploads fw-bold mb-3 w-100 btn_id1"
                            id="Driving"
                            onClick={handleNextUploadClick}
                            buttonText={
                              <>
                                <i className="bi bi-car-front-fill mx-2" />
                                Driver's Licence{" "}
                                <span className="float-end">
                                  <img height="35px" src={upload} alt="" />
                                </span>
                              </>
                            }
                          />

                          <Button
                            className="uploads fw-bold mb-3 w-100 btn_id1"
                            id="passport"
                            onClick={handleNextUploadClick}
                            buttonText={
                              <>
                                <i className="bi bi-airplane mx-2" />
                                Passport{" "}
                                <span className="float-end">
                                  <img height="35px" src={upload} alt="" />
                                </span>
                              </>
                            }
                          />
                          <div>
                      <h6 className="upload-text" style={{fontSize:"12px"}}>When you upload a document, we will receive the documents you have provided, which will be used to progress and/or support your claim(s). Where required, these documents will be shared with your lender(s).</h6></div>
                        </div>
                        <div>
                          <div
                            id="uploarea"
                            className={`${
                              idUploadCurrentSlide !== 1 && "d-none"
                            }`}
                          >
                            <div
                              className=" mb-2 fs-5 backupload"
                              style={{ cursor: "pointer" }}
                              onClick={handleBackbutton}
                            >
                              {" "}
                              <i className="bi bi-arrow-left" /> Back
                            </div>
                            <div className="up-wrap p-1">
                              <div
                                className={`uploaded ${
                                  userfile.preview === "" && "d-none"
                                }`}
                              >
                                <img
                                  className="img-fluid"
                                  style={{ maxWidth: "100%", height: "190px" }}
                                  src={userfile.preview}
                                  alt={userfile.title}
                                />
                              </div>
                              {userfile.preview === "" && (
                                <div className="uploadbox">
                                  <div>
                                    <div>
                                      <i className="bi bi-cloud-upload" />
                                    </div>
                                    <div className="upload-text">
                                      {progressbar
                                        ? "Image Uploading..."
                                        : "Upload image"}
                                    </div>
                                  </div>
                                  <div
                                    className={`text-center imgloader ${
                                      progressbar ? "" : "d-none"
                                    }`}
                                  >
                                    <div
                                      className="progress"
                                      role="progressbar"
                                      aria-label="Animated striped example"
                                      aria-valuenow={75}
                                      aria-valuemin={0}
                                      aria-valuemax={100}
                                    >
                                      <div
                                        className="progress-bar progress-bar-striped progress-bar-animated bg-warning"
                                        style={{ width: "100%" }}
                                      />
                                    </div>
                                  </div>
                                </div>
                              )}
                            </div>
                            {isInValidfFile && (
                                  <div className={`error_msg ${!errorMessage? 'd-none' :''}`}>
                                    {errorMessage.txt}
                                  </div>
                                )}
                            {show && (
                              <div className="row mt-3 upactionbtn">
                                <div className="col-6">
                                  <InputFile
                                    labelClassName="upl-btn w-100 text-center "
                                    onClick={(e) => handleIDuploadButtonClick(e)}
                                    labelName="photo"
                                    iconClass="bi bi-camera pe-2"
                                    iconLabel="Take Photo"
                                    type="file"
                                    accept="image/*"
                                    capture="camera"
                                    id={`${idType}_cam`}
                                    name={`${idType}_cam`}
                                    onChange={handleFileChange}
                                  />
                                </div>
                                <div className="col-6 ps-0">
                                <InputFile
                                    labelClassName="upl-btn  img-uploadbtn w-100 text-center "
                                    onClick={(e) => handleIDuploadButtonClick(e)}
                                    labelName="image"
                                    iconClass="bi bi-image pe-2"
                                    iconLabel="Upload image"
                                    type="file"
                                    accept="image/*"
                                    id={`${idType}_doc`}
                                    name={`${idType}_doc`}
                                    onChange={handleFileChange}
                                  />
                                </div>
                              </div>
                            )}
                            <div
                              className={`row retake mt-3 ${
                                userfile.preview === "" && "d-none"
                              }`}
                            >
                              <div className="col-12  ">
                                <Button
                                 className="upl-btn w-100  "
                                 onClick={retakeClick}
                                 buttonText={
                                  <>
                                     <i className="bi bi-arrow-repeat" />
                                     &nbsp;&nbsp; Retake
                                  </>
                                 }
                                />
                              </div>
                            </div>
                            <div
                              className={`mt-lg-5  mt-md-5 mt-sm-3 mt-4  gonext ${
                                userfile.preview === "" && "d-none"
                              }`}
                            >
                              <button
                                id="next2"
                                className=" btn-comm p-2 w-100 mb-lg-0 mb-md-0 mb-sm-0 mb-3 py-3"
                                onClick={(e) =>
                                  !btnLoader ? handleSubmit(e) : null
                                }
                              >
                                <span className="fw-bold">
                                  Submit
                                  {btnLoader && (
                                    <svg
                                      className="ms-2"
                                      width={30}
                                      xmlns="http://www.w3.org/2000/svg"
                                      viewBox="0 0 200 200"
                                    >
                                      <linearGradient id="a10">
                                        <stop
                                          offset="0"
                                          stopColor="#FFFFFF"
                                          stopOpacity="0"
                                        ></stop>
                                        <stop
                                          offset="1"
                                          stopColor="#FFFFFF"
                                        ></stop>
                                      </linearGradient>
                                      <circle
                                        fill="none"
                                        stroke="url(#a10)"
                                        strokeWidth="25"
                                        strokeLinecap="round"
                                        strokeDasharray="0 44 0 44 0 44 0 44 0 360"
                                        cx="100"
                                        cy="100"
                                        r="70"
                                        transform-origin="center"
                                      >
                                        <animateTransform
                                          type="rotate"
                                          attributeName="transform"
                                          calcMode="discrete"
                                          dur="2"
                                          values="360;324;288;252;216;180;144;108;72;36"
                                          repeatCount="indefinite"
                                        ></animateTransform>
                                      </circle>
                                    </svg>
                                  )}
                                </span>
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                      {/*  Id Uploads */}
                    </div>
                    <div className="mt-lg-3 mt-md-3 mt-sm-2 mt-1 text-center animate__animated animate__fadeInUp">
                     <Button
                      className="skip-btn"
                      id="skipLender"
                      onClick={() => skipIdPage()}
                      buttonText='Skip'
                      gtm_question='verify identity'
                      clickevent='slideclick'
                      gtm_answer=''
                      // gtm_trigger={true}
                     />
                    </div>
                  </div>
                </div>
              </div>
            </section>
            {/* Modal */}
            <div
              className={`modal fade ${vehicleDateModal && "show d-block"}`}
              id="carLender"
              data-bs-backdrop="static"
              data-bs-keyboard="false"
              tabIndex={-1}
              aria-labelledby="staticBackdropLabel"
              aria-hidden="true"
              style={{ background: "rgba(0, 0, 0, .86)" }}
            >
              <div className="modal-dialog modal-dialog-centered modal-md">
                <div className="modal-content car-mdl">
                  <div className="modal-body ">
                    <h2 className="title pt-2">
                    Did you use this lender to finance another vehicle?
                    </h2>
                    <div className="wraper-option banklist mb-4 mt-4 ">
                      <div
                        className={`option-btn optionBank mb-2 selLender ${
                          vehicleSubmitLoader === "8" && "current"
                        }`}
                        onClick={Yesfinanceclick}
                        // onClick={() => vehicleModalClick("6")}
                        // onClick={() => {
                        //   vehicleModalClick("6");
                        //   GTMDataLayer({
                        //     clickEvent: 'slideclick',
                        //     question: 'purchase vehicle',
                        //     answer: '',
                        //   });
                        // }}
                      >
                        <span className="check"> </span>Yes
                        <label htmlFor="lender1 ">
                          <input type="radio" name=" " id="lender1" />
                        </label>
                      </div>
                      <div
                        className={`option-btn optionBank mb-2 selLender ${
                          vehicleSubmitLoader === "9" && "current"
                        }`}
                        // onClick={() => vehicleModalClick("7")}
                        onClick={() => {
                          vehicleModalClick("9");
                          GTMDataLayer({
                            clickEvent: 'slideclick',
                            question: 'purchase vehicle',
                            answer: '',
                          });
                        }}
                      >
                        <span className="check"> </span>No
                        <label htmlFor="lender3 ">
                          <input type="radio" name=" " id="lender3" />
                        </label>
                      </div>
                      <div
                        className={`text-center loader loaderLender ${
                          vehicleSubmitLoader ? "" : "d-none"
                        }`}
                      >
                        <img height={50} src={loader03} alt="" />{" "}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* <FaqAccordion /> */}
            <Footer brand="" />
          </div>
        )}
      </div>
      {!loaderStatus && (
        <AdditionalLender setShow1={setShow1} show1={show1} uuid={query_uuid} />
      )}
    </>
  );
};
export default CarRegistraionV2;
